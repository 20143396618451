import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Layout from "./Layout";
import HomePage from "./components/HomePage";
import TealiumLastPublishPage from "./features/tealiumLastPublish/TealiumLastPublishPage";
import SharePage from "./features/share/SharePage";

export default function AuthenticatedApp() {
	return (
		<BrowserRouter basename={process.env.PUBLIC_URL || ""}>
			<Routes>
				<Route path="/" element={<Layout />}>
					<Route index element={<HomePage />} />
					<Route path="/tealiumLastPublish" element={<TealiumLastPublishPage />} />
					<Route path="/share" element={<SharePage />} />

					{/* <Route
                        path="/workspaces"
                        element={
                            <Requires auth>
                                <WorkspacesPage />
                            </Requires>
                        }
                    /> */}
				</Route>
				<Route path="*" element={<Navigate to="/" replace />} />
			</Routes>
		</BrowserRouter>
	);
}
