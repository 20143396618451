import Link from "./Link";

export default function HomePage() {
	return (
		<div className="section">
			<ul>
				<li className="content">
					<a href={"/inspector"}>Payload Inspector</a>
				</li>
				<li className="content">
					<a href={"./grenade-brothers"}>Grenade Brothers</a>
				</li>
				<li className="content">
					<Link to={"/tealiumLastPublish"}>Tealium Last Publish Checker</Link>
				</li>
				<li className="content">
					<Link to={"/share"}>Share</Link>
				</li>
				<li className="content">
					<a href={"./personal-data"}>Personal Data</a>
				</li>
			</ul>
		</div>
	);
}
