import { ComponentPropsWithoutRef } from "react";

interface TableProps<T> {
	data: T[];
	columns: ColumnConfig<T>[];
	sortInvert?: boolean;
	idFunction: (item: T) => string;
	sortField?: (item: T) => number;
	onRowClick?: (item: T) => void;
	rowAttributesFn?: (item: T) => ComponentPropsWithoutRef<"tr">;
}

function Table<T>({ data, idFunction, columns, sortField, sortInvert, onRowClick, rowAttributesFn }: TableProps<T>) {
	let sortedData = sortData(data, sortField, sortInvert || false);

	const renderedHeaders = columns.map((columns, colIndex) => {
		const columnName = columns.hideColumnName ? null : columns.columnName;
		return <th key={colIndex + columns.columnName}>{columnName}</th>;
	});

	const renderedRows = sortedData.map((item) => {
		const rowKey = idFunction(item);
		const renderedCells = columns.map((column, colIndex) => {
			const cellKey = colIndex + rowKey + column.columnName;
			return (
				<td key={cellKey} style={{ verticalAlign: "middle" }}>
					{column.content(item)}
				</td>
			);
		});
		const rowAttributesConcrete = (rowAttributesFn && rowAttributesFn(item)) || null;
		return (
			<tr onClick={(event) => onRowClick && !event.isDefaultPrevented() && onRowClick(item)} {...rowAttributesConcrete} key={rowKey}>
				{renderedCells}
			</tr>
		);
	});

	const tableClasses = `table is-bordered is-fullwidth is-hoverable ${(onRowClick && "is-clickable") || ""}`;
	return (
		<table className={tableClasses}>
			<thead>
				<tr>{renderedHeaders}</tr>
			</thead>
			<tbody>{renderedRows}</tbody>
		</table>
	);
}

export interface ColumnConfig<T> {
	columnName: string;
	content: (blob: T) => string | JSX.Element;
	hideColumnName?: boolean;
}

export default Table;

function sortData<T>(data: T[], sortField: ((item: T) => number) | undefined, sortInvert: boolean): T[] {
	if (sortField) {
		return data.slice(0).sort((a, b) => sortField(sortInvert ? b : a) - sortField(sortInvert ? a : b));
	} else {
		return data;
	}
}
