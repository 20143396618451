import { LinkProps, Link as ReactLink } from "react-router-dom";

interface Props extends LinkProps {
	focusOnClick?: boolean;
}

export default function Link({ focusOnClick, onClick, children, ...attributes }: Props) {
	return (
		<ReactLink
			onClick={(event) => {
				!focusOnClick && (event.target as HTMLElement).closest("a")?.blur();
				onClick && onClick(event);
			}}
			{...attributes}
		>
			{children}
		</ReactLink>
	);
}
