import { ParseError } from "../../errors/Errors";

export default interface TealiumLastPublishStateResponse {
	lastProdPublish: number;
	lastQaPublish: number;
	lastDevPublish: number;
}

export function parseTealiumLastPublishStateResponse(obj: any): TealiumLastPublishStateResponse {
	if (!obj) throw new ParseError();
	if (typeof obj.lastProdPublish !== "number") throw new ParseError();
	if (typeof obj.lastQaPublish !== "number") throw new ParseError();
	if (typeof obj.lastDevPublish !== "number") throw new ParseError();
	return {
		lastProdPublish: obj.lastProdPublish,
		lastQaPublish: obj.lastQaPublish,
		lastDevPublish: obj.lastDevPublish,
	};
}
