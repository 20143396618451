import { useEffect, useState } from "react";
import request from "../../util/requests";
import { parseTealiumLastPublishStateResponse } from "./TealiumLastPublishStateResponse";
import Table from "../../components/Table";

const profilesToCheck = [
	"colruytgroup/acoustic-global",
	"colruytgroup/acoustic-iframe",
	"colruytgroup/aem-global",
	"colruytgroup/bioplanet-aem",
	"colruytgroup/blog.colruytgroup.com",
	"colruytgroup/boni-v1",
	"colruytgroup/celebrus-colruyt.be",
	"colruytgroup/cg-styleguide",
	"colruytgroup/cga",
	"colruytgroup/cga-magazine",
	"colruytgroup/cgablog",
	"colruytgroup/chefrosemary",
	"colruytgroup/citycollect",
	"colruytgroup/clp-mc-handboek",
	"colruytgroup/clp-wine-kiosk-v1",
	"colruytgroup/codifrance",
	"colruytgroup/colex",
	"colruytgroup/collectandgoapp",
	"colruytgroup/collectandgobe",
	"colruytgroup/collectandgofr",
	"colruytgroup/collectandgofr-v1",
	"colruytgroup/collibrifoundation",
	"colruytgroup/collishop",
	"colruytgroup/collishop-content",
	"colruytgroup/collishop-eindejaar",
	"colruytgroup/collishop-mini",
	"colruytgroup/collishop.b2b",
	"colruytgroup/collishop.b2b.giftcard",
	"colruytgroup/collishop.b2b.loyalty",
	"colruytgroup/collishop.b2b.vakkinddag",
	"colruytgroup/colruyt",
	"colruytgroup/colruyt-iframes",
	"colruytgroup/colruyt.be",
	"colruytgroup/colruyt.beenhouwerij",
	"colruytgroup/colruytexport",
	"colruytgroup/colruytgroup",
	"colruytgroup/colruytgroup.com",
	"colruytgroup/cookie-consent-one-trust",
	"colruytgroup/cpq-jobs",
	"colruytgroup/cru",
	"colruytgroup/cru-webshop",
	"colruytgroup/dats24",
	"colruytgroup/dats24-b2b-app-v1",
	"colruytgroup/dats24-b2b-portal",
	"colruytgroup/dats24-v1",
	"colruytgroup/dreambaby",
	"colruytgroup/dreambaby.donation",
	"colruytgroup/dreambaby.list",
	"colruytgroup/dreambaby.shop",
	"colruytgroup/dreambaby.tweedehands",
	"colruytgroup/dreamland",
	"colruytgroup/dreamland-events",
	"colruytgroup/dreamland-gift",
	"colruytgroup/dreamland-giftfinder",
	"colruytgroup/dreamland-sint",
	"colruytgroup/dreamland-vouchers",
	"colruytgroup/dreamland-wishlist",
	"colruytgroup/efood",
	"colruytgroup/efood-app",
	"colruytgroup/efood-deals",
	"colruytgroup/efood-webviews",
	"colruytgroup/eoly",
	"colruytgroup/event-debug",
	"colruytgroup/event.adblocker",
	"colruytgroup/global-adobe-analytics",
	"colruytgroup/global-adobe-audience-manager",
	"colruytgroup/global-adobe-target",
	"colruytgroup/global-adobe-target-api",
	"colruytgroup/global-config",
	"colruytgroup/global-debug-logger",
	"colruytgroup/global-eventstream",
	"colruytgroup/global-facebook",
	"colruytgroup/global-ga4",
	"colruytgroup/global-google-analytics",
	"colruytgroup/global-mobile",
	"colruytgroup/global-onetrust",
	"colruytgroup/global-tealium-listener",
	"colruytgroup/incontact",
	"colruytgroup/internal-blogs",
	"colruytgroup/ito",
	"colruytgroup/jobs.colruytgroup.com",
	"colruytgroup/jobsite",
	"colruytgroup/jobsite-aem",
	"colruytgroup/kinderfeest.collishop.b2b",
	"colruytgroup/klassewijnen.colruyt.be",
	"colruytgroup/main",
	"colruytgroup/mycolruyt.app.android",
	"colruytgroup/mycolruyt.app.ios",
	"colruytgroup/mycolruyt.app.lib",
	"colruytgroup/neo",
	"colruytgroup/nutriscore",
	"colruytgroup/okay",
	"colruytgroup/okay-aem",
	"colruytgroup/okay-eindejaar",
	"colruytgroup/ontdekjecolruytwijn",
	"colruytgroup/primeurwijn",
	"colruytgroup/prodmark",
	"colruytgroup/qualifio-iframe",
	"colruytgroup/realestate",
	"colruytgroup/retailpartnerscolruytgroup",
	"colruytgroup/rpcg-portal",
	"colruytgroup/solucious",
	"colruytgroup/spar",
	"colruytgroup/spar-eindejaar",
	"colruytgroup/spar-wine-app",
	"colruytgroup/sparretail",
	"colruytgroup/sparretail-reservatie",
	"colruytgroup/spott-iframe",
	"colruytgroup/swf-widget-v1",
	"colruytgroup/symeta",
	"colruytgroup/test",
	"colruytgroup/test-mvs",
	"colruytgroup/udata-to-native-app",
	"colruytgroup/webforms",
	"colruytgroup/xtra",
	"colruytgroup/xtra-app-android",
	"colruytgroup/xtra-app-ios",
	"colruytgroup/xtra-data",
	"colruytgroup/xtradcs-auth",
	"colruytgroup/xtradcs-goforward",
	"colruytgroup/xtradcs-web",
	"colruytgroup/xtradcs.app",
	"colruytgroup/xtraportal",
];

export default function TealiumLastPublishManager() {
	const [publishStates, setPublishStates] = useState<{ [key: string]: TealiumLastPublishState }>({});
	const [loadingIndex, setLoadingIndex] = useState<number>(-1);

	const refreshStates = async () => {
		if (loadingIndex !== -1) return;
		setPublishStates({});
		setLoadingIndex(0);
	};

	useEffect(() => {
		if (loadingIndex === -1) return;
		(async function () {
			try {
				const state = await loadTealiumState(profilesToCheck[loadingIndex]);
				setPublishStates({ ...publishStates, [state.accountAndProfile]: state });
			} catch (e) {
				console.error(`Crashed on ${profilesToCheck[loadingIndex]}`);
			}
			setLoadingIndex(loadingIndex >= profilesToCheck.length - 1 ? -1 : loadingIndex + 1);
		})();
	}, [loadingIndex, publishStates]);

	return (
		<div>
			<div>
				<h1>Tealium Last Publish Manager</h1>
				<button disabled={loadingIndex !== -1} onClick={refreshStates}>
					Refresh
				</button>
			</div>
			{loadingIndex !== -1 && (
				<div>
					Loading profile {loadingIndex + 1} of {profilesToCheck.length}...
				</div>
			)}
			<div>
				<Table
					data={Object.keys(publishStates).map((key) => publishStates[key])}
					columns={[
						{
							columnName: "Account",
							content: (state) => state.account,
						},
						{
							columnName: "Profile",
							content: (state) => state.profile,
						},
						{
							columnName: "PROD",
							content: (state) => toPrettyDateTime(state.lastProdPublish),
						},
						{
							columnName: "QA",
							content: (state) => toPrettyDateTime(state.lastQaPublish),
						},
						{
							columnName: "DEV",
							content: (state) => toPrettyDateTime(state.lastDevPublish),
						},
					]}
					idFunction={(item) => item.accountAndProfile}
					sortField={(item) => item.lastProdPublish?.getTime() || Infinity}
					sortInvert
					rowAttributesFn={(item) => ({
						style: {
							backgroundColor: getDaysAgoBackgroundColor(item.lastProdPublish?.getTime()),
						},
					})}
				/>
			</div>
		</div>
	);
}

function toPrettyDateTime(date: Date | null): string {
	if (date === null) return "Error";
	const year = date.getFullYear();
	const month = (date.getMonth() + 1).toString().padStart(2, "0");
	const day = date.getDate().toString().padStart(2, "0");
	const hour = date.getHours().toString().padStart(2, "0");
	const minute = date.getMinutes().toString().padStart(2, "0");
	return `${year}-${month}-${day} at ${hour}:${minute}`;
}

function getDaysAgoBackgroundColor(publishTimestamp: number | undefined) {
	if (!publishTimestamp) return "#810000";
	const diffMs = Date.now() - publishTimestamp;
	const DAY = 1000 * 60 * 60 * 24;
	if (diffMs < DAY) return "#00690a";
	if (diffMs < DAY * 3) return "#0e5214";
	if (diffMs < DAY * 7) return "#345937";
	if (diffMs < DAY * 30) return "#3a4d3c";
	if (diffMs < DAY * 120) return "#485449";
	if (diffMs < DAY * 365) return "#353635";
}

async function loadTealiumState(accountAndProfile: string): Promise<TealiumLastPublishState> {
	const account = accountAndProfile.split("/")[0];
	const profile = accountAndProfile.split("/")[1];
	try {
		const response = await request("GET", `/tealium/tealiumPublishInfo/${account}/${profile}`);
		const json = await response.json();
		const tealiumLastPublishStateResponse = parseTealiumLastPublishStateResponse(json.lastPublishInfo);
		return {
			accountAndProfile: accountAndProfile,
			account: account,
			profile: profile,
			lastProdPublish: new Date(tealiumLastPublishStateResponse.lastProdPublish),
			lastQaPublish: new Date(tealiumLastPublishStateResponse.lastQaPublish),
			lastDevPublish: new Date(tealiumLastPublishStateResponse.lastDevPublish),
		};
	} catch (e) {
		console.error(`Crashed on ${accountAndProfile}`);
		return {
			accountAndProfile: accountAndProfile,
			account: account,
			profile: profile,
			lastProdPublish: null,
			lastQaPublish: null,
			lastDevPublish: null,
		};
	}
}

interface TealiumLastPublishState {
	accountAndProfile: string;
	account: string;
	profile: string;
	lastProdPublish: Date | null;
	lastQaPublish: Date | null;
	lastDevPublish: Date | null;
}
