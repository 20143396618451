import { Outlet } from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer";

export default function Layout() {
	return (
		<div id="main-wrapper" className="container">
			<Header />
			<Outlet />
			<Footer />
		</div>
	);
}
