import { useEffect, useState } from "react";
import { isAuthenticated as isAuthenticatedApi } from "./core/auth_api";
import LoginForm from "./components/LoginForm";
import AuthenticatedApp from "./AuthenticatedApp";

export default function App() {
	const [isAuthenticated, setIsAuthenticated] = useState<boolean | null>(null);
	const [checkAuthentication, setCheckAuthentication] = useState<boolean>(true);

	useEffect(() => {
		if (!checkAuthentication) return;
		(async function () {
			setCheckAuthentication(false);
			setIsAuthenticated(await isAuthenticatedApi());
		})();
	}, [checkAuthentication]);

	useEffect(() => {
		if (!isAuthenticated) return;
		const redirectUrl = window.location.search.match(/[?&]authRedirect=([^#&]+)/)?.pop();
		if (redirectUrl) {
			const newUrl = `${window.location.protocol}//${window.location.hostname}${
				window.location.port ? ":" + window.location.port : ""
			}${decodeURIComponent(redirectUrl)}`;
			window.location.href = newUrl;
		}
	}, [isAuthenticated]);

	return (
		<div>
			{isAuthenticated === null && "Loading..."}
			{isAuthenticated === false && <LoginForm onLogin={() => setCheckAuthentication(true)} />}
			{isAuthenticated === true && <AuthenticatedApp />}
		</div>
	);
}
