import { ConnectionFailedError } from "../errors/Errors";

const BASE_URL = (process.env.REACT_APP_API_BASE_URL || process.env.PUBLIC_URL || "").replace("{PUBLIC_URL}", process.env.PUBLIC_URL || "");

/**
 * Make an HTTP request.
 *
 * @param {string} method The HTTP method (Possible value: "GET", "POST", "PUT", "DELETE").
 * @param {string} url The endpoint path to send a request to (e.g. "/auth").
 * @param {RequestInit} [config] Additional request parameters.
 * @throws {ConnectionFailedError} Will be thrown when the connection fails for any reason.
 * @returns {Promise<Response>} HTTP response object.
 */
export default async function request(method: string, url: string, config?: RequestInit): Promise<Response> {
	method = method && method.toUpperCase();
	if (!method || ["GET", "POST", "PUT", "DELETE"].indexOf(method) === -1) throw new Error(`Invalid method specified: "${method}"`);
	if (!url) throw new Error("URL must be specified");

	url = url.startsWith("http") ? url : BASE_URL + url;
	config = config || {};
	config.method = method;
	const baseHeaders: HeadersInit = {};
	baseHeaders["Content-Type"] = "application/json";
	const customHeaders: HeadersInit = config.headers || {};
	config.headers = { ...baseHeaders, ...customHeaders };
	config.credentials = "include";
	try {
		return await fetch(url, config);
	} catch (e: any) {
		throw new ConnectionFailedError(e.message || "Connection failed");
	}
}
