import { useCallback, useEffect, useState } from "react";
import request from "../../util/requests";

export default function SharePage() {
	const [text, setText] = useState<string>("");

	const onTextUpdated = useCallback((text: string) => {
		setText(text);
		(async function () {
			const response = await request("put", "/share/text", {
				body: JSON.stringify({ text: text }),
			});
			if (response.status !== 204) throw new Error("Unexpected status code: " + response.status);
		})();
	}, []);

	useEffect(() => {
		(async function () {
			const response = await request("get", "/share/text");
			if (response.status !== 200) throw new Error("Unexpected status code: " + response.status);
			const jsonBody = await response.json();
			if (typeof jsonBody.text !== "string") throw new Error("Expected body.text to be a string, but found " + typeof jsonBody.text);
			setText(jsonBody.text);
		})();
	}, []);

	return (
		<div>
			<textarea
				value={text}
				onChange={(event) => onTextUpdated(event.target.value)}
				style={{
					width: "700px",
					height: "700px",
					backgroundColor: "#444444",
					color: "#dddddd",
					padding: "4px",
				}}
			></textarea>
		</div>
	);
}
